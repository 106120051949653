<template>
  <!-- pc端 导航方块  -->
  <div class="norem-navigation" ref="navigation">
    <div class="header" @click="router.push('/')">
      <img v-if="showFlag" src="@/assets/home/logo1.jpg" alt="" />
      <img v-else src="@/assets/home/logo.jpg" alt="" />
    </div>
    <div class="bottom">
      <div class="image" @click="router.push('/')">
        <img src="@/assets/home/home.jpg" alt="" />
      </div>
      <div class="image" @click="router.push('/account')">
        <img v-if="showFlag" src="@/assets/home/sousuo.png" alt="" />
        <img v-else src="@/assets/home/sousuo.png" alt="" />
      </div>
    </div>
  </div>
  <!-- md端 导航头部 -->
  <div class="norem-header" ref="noremHeader">
    <div class="logo" @click="router.push('/')">
      <img src="@/assets/home/logo.jpg" alt="" />
    </div>
    <p class="title">rmGames</p>
    <div class="account" @click="router.push('/account')">
      <el-icon><UserFilled /></el-icon>
    </div>
  </div>
  <router-view />
</template>

<script setup>
import { useRoute, useRouter } from "vue-router";
import { UserFilled } from "@element-plus/icons-vue";
import { ref, watch } from "vue";

const route = useRoute();
const router = useRouter();

// 新优化
const navigation = ref(null);
const noremHeader = ref(null);
const showFlag = ref(false);
watch(
  () => route,
  (n) => {
    if (n) {
      if (
        n.path === "/coin/snr=2_5_6__more-coin-pay" ||
        n.path === "/join/snr=1_4_4__more-content-login"
      ) {
        showFlag.value = true; 
      }else{
        showFlag.value = false
      }
    }
  },
  {
    immediate: true,
    deep: true,
  }
);

</script>

<style lang="scss">
#app {
  width: 100%;
  height: 100%;

  @media screen and (min-width: 1001px) {
    .norem-navigation {
      padding: 6px;
      width: 90px;
      height: 90px;
      position: fixed;
      top: 200px;
      left: 10px;
      border-radius: 10px;
      border: 1px solid #000;
      box-shadow: 0px 2px 6px 1px rgba(0, 0, 0, 0.2);
      background: #fff;
      opacity: 0.98;
      z-index: 2000;
      .header {
        margin-bottom: 2px;
        width: 100%;
        height: 50%;
        border-radius: 4px;
        img {
          width: 100%;
          height: 100%;
          border-radius: 4px;
          object-fit: cover;
          cursor: pointer;
          &:hover {
            transform: scale(1.05);
          }
        }
      }
      .bottom {
        height: 50%;
        display: flex;
        .image {
          width: 50%;
          height: 100%;
          border-radius: 4px;
          img {
            width: 100%;
            height: 100%;
            border: 1px solid #f2f0f0;
            border-radius: 4px;
            object-fit: cover;
            cursor: pointer;
            &:hover {
              transform: scale(1.05);
            }
          }
        }
        div:nth-child(1) {
          margin-right: 2px;
        }
        div:nth-child(2) {
          margin-left: 2px;
        }
      }
    }
    .norem-header {
      display: none;
    }
  }
  @media screen and (max-width: 1000px) and (min-width: 601px) {
    .norem-header {
      display: none;
    }
    .norem-navigation {
      display: none;
    }
  }
  @media screen and (max-width: 600px) {
    .norem-navigation {
      display: none;
    }
    .norem-header {
      padding: 0 10px;
      width: 100%;
      height: 60px;
      position: fixed;
      top: 0;
      left: 0;
      background: #1f1f72;
      display: flex;
      justify-content: space-between;
      align-items: center;
      z-index: 2000;
      .logo {
        img {
          width: 80px;
          height: 40px;
          object-fit: contain;
          border: 1px solid #756e6e;
          border-radius: 4px;
        }
      }
      .title {
        font-size: 16px;
        font-weight: bold;
        text-align: center;
        color: #fff;
      }
      .account {
        width: 40px;
        height: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        background: #ffffff1a;
        border-radius: 50%;
        .el-icon {
          font-size: 26px;
          color: #fff;
          &:hover {
            color: #666a7f;
          }
        }
      }
    }
  }
}
</style>
